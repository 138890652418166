<template>
  <div id="app">
    <div v-if="!submitted">
    <AdminLogovanje @updateNaslovna="submit" />
    </div>
    <div v-else>
    <h3>Надзороно лице</h3>
    <form>
        <input type="hidden" v-model="ID" name="ID" value="">
        <div class="form-horizontal">
            <div v-show="!pass" style="color:red;text-align:center">{{msg}}</div>
            <div v-show="pass && msg != ''" style="color:#008fb8;text-align:center">{{msg}}</div>
        </div>
        <div class="form-group">
            <label class="control-label col-md-2" for="Email">Емаил-ови*</label>
            <div class="col-md-10">
                <textarea cols="20" htmlAttributes="{ class = form-control }" id="Email" name="Email" v-model="Email"  rows="2"></textarea>
                <div><i>Емаил-ове одвајати са (;)</i></div>
            </div>
        </div>
        <div class="form-group">
            <label class="control-label col-md-2" for="Ime">Назив групе</label>
            <div class="col-md-10">
                <input class="form-control text-box single-line" data-val="true" data-val-required="Унесите име!" id="Ime" name="Ime" v-model="ImePrezime" type="text" value="" />
                <span class="field-validation-valid text-danger" data-valmsg-for="Ime" data-valmsg-replace="true"></span>
            </div>
        </div>
        <div class="form-group" align="right">
            <div class="col-md-offset-2 col-md-10">
                <button type="submit" value="" @click.prevent="submit" class="btn btn-default" v-if="ID == 0">Додај</button>
                <button type="submit" value="" @click.prevent="submit" class="btn btn-default" v-else>Измени</button>
            </div>
        </div>
    </form>
    <div>
        <a href="../administracija_nadzorna_lica.html">Назад</a>
    </div>
   </div>
  </div>
</template>

<script>
import AdminLogovanje from '../../../components/AdminLogovanje.vue'
import axios from 'axios';
import $ from 'jquery';
import appConfig from '../../../js/configuration.js'
import common from '../../../js/common.js'
//require('../../../js/configuration.js');
//require('../../../js/common.js');
//require('@/js/jquery.magnific-popup.js')

export default {
  name: 'AdminNadzornoLice',
  data: function(){
    return {
        ID: 0,
        ImePrezime: '',
        Email: '',
        msg: '',
      submitted: false,
      korisnik: null,
      pass: true
    }
  },
  components: {
    AdminLogovanje,
  },  
   methods: {
       init: function(){
          var _this = this;
          var id = common.getParam(this.$route,"id");
          common.checkAdminSession(appConfig).then(function(response){
              _this.submitted = response.data.result;
              if(!_this.submitted) {
                  $('#admin_logovanje').show();
              }
              else{
                    _this.fetch(id);
              }
          });
          
         // return this.submitted;
      },
      fetch: function(id) {
            
        if(id > 0) {
            var _this = this;
            var userToken = localStorage.getItem('adminToken');
          if(userToken != null){
                var postData = new FormData();
                postData.append('token',userToken);
                postData.append('id',id);
                axios.post(appConfig.backendUrl + 'adminNadzornoLice.php',postData).then(function(response){
                    var k = response.data[0];
                        _this.ID = k.ID;
                        _this.Email = k.Email;
                        _this.ImePrezime= k.ImePrezime;
                });
            }

        }
      },
        submit: function(){
          var _this = this;
          common.checkAdminSession(appConfig).then(function(response){
              _this.submitted = response.data.result;
              if(!_this.submitted) {
                  $('#admin_logovanje').show();
              }
              else{
                    var postData = new FormData();
                    postData.append('ID',_this.ID);
                    postData.append('Email', _this.Email);
                    postData.append('ImePrezime',_this.ImePrezime);
                    axios.post(appConfig.backendUrl + 'adminNadzornoLiceInsertUpdate.php', postData).then(function(response){
                       
                        if(response.data.result === true){
                            _this.pass = true;
                        //
                            if(_this.ID == 0){
                                document.location.href = "../administracija_nadzorna_lica.html"; 
                            }
                            else {
                                _this.msg = response.data.msg;
                            }

                        }
                        else{
                        _this.pass = false;
                        _this.msg = response.data.msg;
                        }
                    
                    });
                   //console.log('submit Registracije');
              }
          });
        },
        submitRegistracije: function(){
         
          //console.log('submit Registracije');
        },
    },

    mounted: function(){
      //console.log("created 1");
     //  //console.log('Params: ', );
      this.init();
      //console.log(this.submitted);
    }
}
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  /* color: #2c3e50;
  margin-top: 60px; */
}
</style>
