<template>
    <div id="app">
        <form>
            <input type="hidden" v-model="ID" name="ID" value="">
            <div class="form-horizontal">
            <div v-show="!pass" style="color:red;text-align:center">{{msg}}</div>
             <div v-show="pass && msg != ''" style="color:#008fb8;text-align:center">{{msg}}</div>
            <div style="width:46%;display: inline-block; padding: 20px; vertical-align: top;padding-top: 0">
                <h3>Приступни подаци</h3>
                <div class="form-group">
                    <label class="control-label col-md-2" for="Ime">Име*</label>
                    <div class="col-md-10">
                        <input class="form-control text-box single-line" data-val="true" data-val-required="Унесите име!" id="Ime" name="Ime" v-model="Ime" type="text" value="" />
                        <span class="field-validation-valid text-danger" data-valmsg-for="Ime" data-valmsg-replace="true"></span>
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label col-md-2" for="Prezime">Презиме*</label>
                    <div class="col-md-10">
                        <input class="form-control text-box single-line" data-val="true" data-val-required="Унесите презиме!" id="Prezime" name="Prezime" v-model="Prezime" type="text" value="" />
                        <span class="field-validation-valid text-danger" data-valmsg-for="Prezime" data-valmsg-replace="true"></span>
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label col-md-2" for="BrojLicence">Број лиценце*</label>
                    <div class="col-md-10">
                        <input class="form-control text-box single-line" data-val="true" data-val-required="Унесите број лиценце!" id="BrojLicence" name="BrojLicence" v-model="BrojLicence" type="text" value="" />
                        <span class="field-validation-valid text-danger" data-valmsg-for="BrojLicence" data-valmsg-replace="true"></span>
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label col-md-2" for="Telefon">Телефон*</label>
                    <div class="col-md-10">
                        <input class="form-control text-box single-line" data-val="true" data-val-required="Унесите телефон!" id="Telefon" name="Telefon" v-model="Telefon" type="text" value="" />
                        <span class="field-validation-valid text-danger" data-valmsg-for="Telefon" data-valmsg-replace="true"></span>
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label col-md-2" for="Email">Емаил*</label>
                    <div class="col-md-10">
                        <input class="form-control text-box single-line" data-val="true" data-val-regex="Емаил није валидан!" data-val-regex-pattern="^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$" data-val-required="Унесите Е-маил!" id="Email" name="Email" v-model="Email" type="text" value="" />
                        <span class="field-validation-valid text-danger" data-valmsg-for="Email" data-valmsg-replace="true"></span>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-md-2" for="Lozinka">Лозинка*</label>
                    <div class="col-md-10">
                        <input class="form-control text-box single-line password" data-val="true" data-val-required="Унесите лозинку!" id="Lozinka" name="Lozinka" v-model="Lozinka" type="password" value="" />
                        <span class="field-validation-valid text-danger" data-valmsg-for="Lozinka" data-valmsg-replace="true"></span>
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label col-md-2" for="PotvrdaLozinkе">Потврда лозинке*</label>
                    <div class="col-md-10">
                        <input class="form-control text-box single-line password" data-val="true" data-val-equalto="&#39;PotvrdaLozinkе&#39; and &#39;Lozinka&#39; do not match." data-val-equalto-other="*.Lozinka" data-val-required="Унесите потврду лозинкe!" id="PotvrdaLozink_" name="PotvrdaLozinkе" v-model="PotvrdaLozinkе" type="password" value="" />
                        <span class="field-validation-valid text-danger" data-valmsg-for="PotvrdaLozinkе" data-valmsg-replace="true"></span>
                    </div>
                </div>


            </div>
            <div style="width:45%; display: inline-block;padding: 20px; vertical-align: top;padding-top: 0">
                <h3>Више детаља</h3>
                <div class="form-group">
                    <label class="control-label col-md-2" for="Adresa">Адреса*</label>
                    <div class="col-md-10">
                        <input class="form-control text-box single-line" data-val="true" data-val-required="Унесите адресу!" id="Adresa" name="Adresa" v-model="Adresa" type="text" value="" />
                        <span class="field-validation-valid text-danger" data-valmsg-for="Adresa" data-valmsg-replace="true"></span>
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label col-md-2" for="Grad">Град*</label>
                    <div class="col-md-10">
                        <input class="form-control text-box single-line" data-val="true" data-val-required="Унесите град!" id="Grad" name="Grad" v-model="Grad" type="text" value="" />
                        <span class="field-validation-valid text-danger" data-valmsg-for="Grad" data-valmsg-replace="true"></span>
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label col-md-2" for="PostanskiBroj">Поштански број*</label>
                    <div class="col-md-10">
                        <input class="form-control text-box single-line" data-val="true" data-val-required="Унесите државу!" id="PostanskiBroj" name="PostanskiBroj" v-model="PostanskiBroj" type="text" value="" />
                        <span class="field-validation-valid text-danger" data-valmsg-for="PostanskiBroj" data-valmsg-replace="true"></span>
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label col-md-2" for="Zemlja">Земља*</label>
                    <div class="col-md-10">
                        <input class="form-control text-box single-line" data-val="true" data-val-required="Унесите Земљу!" id="Zemlja" name="Zemlja"  v-model="Zemlja" type="text" value="" />
                        <span class="field-validation-valid text-danger" data-valmsg-for="Zemlja" data-valmsg-replace="true"></span>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-md-2" for="Institucija">Институција</label>
                    <div class="col-md-10">
                        <input class="form-control text-box single-line" data-val="true" data-val-required="Унесите Институцију!" id="Institucija" name="Institucija" v-model="Institucija" type="text" value="" />
                        <span class="field-validation-valid text-danger" data-valmsg-for="Institucija" data-valmsg-replace="true"></span>
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label col-md-2" for="Specijalnost">Специјалност</label>
                    <div class="col-md-10">
                        <input class="form-control text-box single-line" data-val="true" data-val-required="Унесите специјалност!" id="Specijalnost" name="Specijalnost" v-model="Specijalnost" type="text" value="" />
                        <span class="field-validation-valid text-danger" data-valmsg-for="Specijalnost" data-valmsg-replace="true"></span>
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label col-md-2" for="Subspecijalnost">Субспецијалност</label>
                    <div class="col-md-10">
                        <input class="form-control text-box single-line" data-val="true" data-val-required="Унесите субспецијалност!" id="Subspecijalnost" name="Subspecijalnost" v-model="Subspecijalnost" type="text" value="" />
                        <span class="field-validation-valid text-danger" data-valmsg-for="Subspecijalnost" data-valmsg-replace="true"></span>
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label col-md-2" for="StrucniNaziv">Стручни назив</label>
                    <div class="col-md-10">
                        <input class="form-control text-box single-line" data-val="true" data-val-required="Унесите стручни назив!" id="StrucniNaziv" name="StrucniNaziv" v-model="StrucniNaziv" type="text" value="" />
                        <span class="field-validation-valid text-danger" data-valmsg-for="StrucniNaziv" data-valmsg-replace="true"></span>
                    </div>
                </div>

                <div class="form-group" align="right">
                    <div class="col-md-offset-2 col-md-10">
                        <button type="submit" value="" @click.prevent="process" class="btn btn-default">Креирај налог</button>
                    </div>
                </div>
            </div>
        </div>
        </form>
    </div>

</template>
<script>
import axios from 'axios';
import appConfig from '../js/configuration.js';
import common from '../js/common.js';

export default {
  name: 'App',
   data: function() {
        return {
        ID: 0,
        Ime: '',
        Prezime: '',
        msg: '',
        BrojLicence: '',
        Telefon: '',
        Email: '',
        Lozinka: '',
        PotvrdaLozinkе: '',
        Adresa: '',
        Grad: '',
        PostanskiBroj: '',
        Zemlja: '',
        Institucija: '',
        Specijalnost: '',
        Subspecijalnost: '',
        StrucniNaziv: '',
        pass: true
      }
    },
    methods: {
        fetch: function(){
             var _this = this;
        // var userToken = localStorage.getItem('adminToken');
        //   if(userToken != null)
          {
           
             var postData = new FormData();
             //postData.append('token',userToken);
             postData.append('id',common.getParam(this.$route,"id"));
            axios.post(appConfig.backendUrl + 'korisnik.php',postData).then(function(response){
                  var k = response.data[0];
                   _this.ID = k.ID;
                   _this.Ime= k.Ime;
                    _this.Prezime= k.Prezime;
                    _this.BrojLicence = k.BrojLicence;
                    _this.Telefon= k.Telefon;
                    _this.Email= k.Email;
                    _this.Lozinka= k.Lozinka;
                    _this.PotvrdaLozinkе= k.Lozinka;
                    _this.Adresa= k.Adresa;
                    _this.Grad= k.Grad;
                    _this.PostanskiBroj= k.PostanskiBroj;
                    _this.Zemlja= k.Zemlja;
                    _this.Institucija= k.Institucija;
                    _this.Specijalnost= k.Specijalnost;
                    _this.Subspecijalnost= k.Subspecijalnost;
                    _this.StrucniNaziv= k.StrucniNaziv;
              });
          }
        },
          fetchByToken: function(){
          var _this = this;
          var userToken = localStorage.getItem('userToken');
          if(userToken != null)
          {
           
             var postData = new FormData();
             postData.append('token',userToken);
            axios.post(appConfig.backendUrl + 'korisnik.php',postData).then(function(response){
                  var k = response.data[0];
                   _this.ID = k.ID;
                   _this.Ime= k.Ime;
                    _this.Prezime= k.Prezime;
                    _this.BrojLicence = k.BrojLicence;
                    _this.Telefon= k.Telefon;
                    _this.Email= k.Email;
                    _this.Lozinka= k.Lozinka;
                    _this.PotvrdaLozinkе= k.Lozinka;
                    _this.Adresa= k.Adresa;
                    _this.Grad= k.Grad;
                    _this.PostanskiBroj= k.PostanskiBroj;
                    _this.Zemlja= k.Zemlja;
                    _this.Institucija= k.Institucija;
                    _this.Specijalnost= k.Specijalnost;
                    _this.Subspecijalnost= k.Subspecijalnost;
                    _this.StrucniNaziv= k.StrucniNaziv;
              });
          }
        },
        process: function(){
            var _this = this;
             var postData = new FormData();
             postData.append('ID',this.ID);
            postData.append('Ime',this.Ime);
            postData.append('Prezime', this.Prezime);
            postData.append('BrojLicence',this.BrojLicence);
            postData.append('Telefon', this.Telefon);
            postData.append('Email',this.Email);
            postData.append('Lozinka', this.Lozinka);
            postData.append('PotvrdaLozinkе',this.PotvrdaLozinkе);
            postData.append('Adresa', this.Adresa);
            postData.append('Grad',this.Grad);
            postData.append('Zemlja', this.Zemlja);
            postData.append('PostanskiBroj',this.PostanskiBroj);
            postData.append('Institucija', this.Institucija);
            postData.append('Specijalnost',this.Specijalnost);
            postData.append('Subspecijalnost', this.Subspecijalnost);
            postData.append('StrucniNaziv',this.StrucniNaziv);
            
            axios.post(appConfig.backendUrl + 'register.php', postData).then(function(response){
                //console.log(_this);
                  //console.log(response.data);
                if(response.data.result === true){
                   _this.pass = true;
                   _this.msg = response.data.msg;
                     _this.$emit('updateRegistracija', {"email" : _this.Email, "pwd" :_this.Lozinka} ); 
                }
                else{
                   _this.pass = false;
                   _this.msg = response.data.msg;
                }
               
            });
          //console.log('process');
        }
    },
    mounted: function(){
            if(this.$route && common.getParam(this.$route,"id") > 0) 
                this.fetch();
            else
                this.fetchByToken();
            //console.log('created moj profil');
    }
}
</script>
