<template>
  <div id="app">
    <div v-if="!submitted">
    <AdminLogovanje @updateNaslovna="submit" />
    </div>
    <div v-else>
       <h3>Детаљи налога контролног панела</h3>
       <AkreditoriProfil  @updateRegistracija="submit" />
  </div>
  </div>
</template>

<script>
import AdminLogovanje from '../../../components/AdminLogovanje.vue'
import AkreditoriProfil from '../../../components/AkreditorProfil.vue'
//import axios from 'axios';
import $ from 'jquery';
import appConfig from '../../../js/configuration.js'
import common from '../../../js/common.js'
//require('../../../js/configuration.js');
//require('../../../js/common.js');
//require('@/js/jquery.magnific-popup.js')

export default {
  name: 'AdminDetaljiAkreditora',
  data: function(){
    return {
      submitted: false,
      korisnik: null
    }
  },
  components: {
    AdminLogovanje,
    AkreditoriProfil
  },  
   methods: {
        checkSession: function(){
          var _this = this;
          common.checkAdminSession(appConfig).then(function(response){
              _this.submitted = response.data.result;
              if(!_this.submitted) $('#admin_logovanje').show();
          });
          
         // return this.submitted;
      },
      fetch: function() {
       
      },
        submit: function(){
          this.submitted = true;
          //console.log(this.submitted);
          //console.log('submit');
        },
        submitRegistracije: function(){
         
          //console.log('submit Registracije');
        },
    },

    mounted: function(){
      //console.log("created 1");
     //  //console.log('Params: ', );
      this.checkSession();
      //console.log(this.submitted);
    }
}
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  /* color: #2c3e50;
  margin-top: 60px; */
}
</style>
