<template>
    <div id="admin_logovanje"  style="margin:auto; padding: 20px;">
    <h3>Пријава</h3>
        <form>
        <div class="form-horizontal">
                    <div class="form-group">
                        <label class="control-label col-md-2" for="Naslov">Емаил</label>
                        <div class="col-md-10">
                            <input class="form-control text-box single-line" data-val="true" data-val-required="Нисте унели корисничко име!" id="UserName" name="UserName"  v-model="email"  type="text" value="">
                            <span class="field-validation-valid text-danger" data-valmsg-for="UserName" data-valmsg-replace="true"></span>
                        </div>
                    </div>


                    <div class="form-group">
                        <label class="control-label col-md-2" for="Naslov">Лозинка</label>
                        <div class="col-md-10">
                            <input class="form-control text-box single-line password" data-val="true" data-val-required="Нисте унели лозинку!" id="Password" name="Password" v-model="password"  type="password" value="">
                            <span class="field-validation-valid text-danger" data-valmsg-for="Password" data-valmsg-replace="true"></span>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="col-md-offset-2 col-md-10">
                            <button type="submit" @click.prevent="process" class="btn btn-primary">Пријава</button>
                        </div>
                    </div>
                </div>
        </form>
    </div>
</template>

<script>

import axios from 'axios';
import appConfig from '../js/configuration.js'

export default {
  name: 'AdminLogovanje',
   data: function() {
        return {
      email: '',
      password: '',
      msg: '',
      pass: true
      }
    },
    methods: {
        process: function(){
            var _this = this;
            var postData = new FormData();
            postData.append('email',this.email);
            postData.append('password', this.password);

            axios.post(appConfig.backendUrl + 'cpanelLogin.php',postData).then(function(response){
                //console.log(_this);
                  //console.log(response.data);
                if(response.data.result === true){
                   _this.pass = true;
                   localStorage.setItem("cpanelToken", response.data.token);
                   _this.msg = '';
                     _this.$emit('updateNaslovna', response.data.result); 
                }
                else{
                   _this.pass = false;
                   _this.msg = 'Неуспела пријава';
                }
               
            });
          //console.log('process');
        }
    },
}
</script>

