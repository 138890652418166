//import $ from 'jquery';
import axios from 'axios';
import $ from 'jquery';

var common = {
    checkAdminSession: function(appConfig){
        var userToken = localStorage.getItem('adminToken');
        var postData = new FormData();
        postData.append('token',userToken);

        return axios.post(appConfig.backendUrl + 'checkAdminSession.php',postData);

    },
    checkCpanelSession: function(appConfig){
        var userToken = localStorage.getItem('cpanelToken');
        var postData = new FormData();
        postData.append('token',userToken);

        return axios.post(appConfig.backendUrl + 'checkCpanelSession.php',postData);

    },
    checkSession: function(appConfig){
        var userToken = localStorage.getItem('userToken');
        var postData = new FormData();
        postData.append('token',userToken);

        return axios.post(appConfig.backendUrl + 'checkSession.php',postData);
    },
    checkTestSession: function(appConfig){
        var userToken = localStorage.getItem('testSesija');
        var postData = new FormData();
        postData.append('token',userToken);

        return axios.post(appConfig.backendUrl + 'checkTestSession.php',postData);
    },
    init: function(){
        var _this = this;
        $('[vp-cloak]').show();
        $('.sadrzaj .animator').animate({ marginLeft: "0px", opacity: "1" }, { duration: 500, complete: function () { } });
        $("#odjava").unbind("click").click(function(e){
            e.preventDefault();
            localStorage.removeItem('userToken');
            document.location.reload();
        });
        $("#pocetna").unbind("click").click(function(e){
            e.preventDefault();
            document.location.href = _this.getRelativeRoot();
        });
        $("#moj-profil").unbind("click").click(function(e){
            e.preventDefault();
            document.location.href = _this.getRelativeRoot() + "moj_profil.html";
        });
        $("#istorija").unbind("click").click(function(e){
            e.preventDefault();
            document.location.href = _this.getRelativeRoot() + "istorija.html";
        });
    },
    adminInit: function(){
        var _this = this;
        $('[vp-cloak]').show();
        $("#odjava").unbind("click").click(function(e){
            e.preventDefault();
            localStorage.removeItem('adminToken');
            document.location.reload();
        });
        $("#aktivne-sesije").unbind("click").click(function(e){
            e.preventDefault();
            document.location.href = _this.getRelativeRoot() + "administracija.html";
        });
        $("#korisnici").unbind("click").click(function(e){
            e.preventDefault();
            document.location.href = _this.getRelativeRoot() + "administracija_korisnici.html";
        });
        $("#aktivni-testovi").unbind("click").click(function(e){
            e.preventDefault();
            document.location.href = _this.getRelativeRoot() + "administracija_aktivni_testovi.html";
        });
        $("#arhiva-testova").unbind("click").click(function(e){
            e.preventDefault();
            document.location.href = _this.getRelativeRoot() + "administracija_arhiva_testova.html";
        });
        $("#nadzorna-lica").unbind("click").click(function(e){
            e.preventDefault();
            document.location.href = _this.getRelativeRoot() + "administracija_nadzorna_lica.html";
        });
        $("#akreditori").unbind("click").click(function(e){
            e.preventDefault();
            document.location.href = _this.getRelativeRoot() + "administracija_kontrolni_panel_nalozi.html";
        });
        // $('.cke_button cke_button__maximize cke_button_off').on("click", function(e){
        //     e.preventDefault();
        //     $('[vp-cloak]').show();
        // });
    },
    cpanelInit: function(){
        var _this = this;
        $('[vp-cloak]').show();
        $("#odjava").unbind("click").click(function(e){
            e.preventDefault();
            localStorage.removeItem('cpanelToken');
            document.location.reload();
        });
        $("#kontrolni-panel").unbind("click").click(function(e){
            e.preventDefault();
            document.location.href = _this.getRelativeRoot() + "kontrolni_panel.html";
        });
        $("#moj-profil").unbind("click").click(function(e){
            e.preventDefault();
            document.location.href = _this.getRelativeRoot() + "kontrolni_panel_moj_profil.html";
        });
    },
    getRelativeRoot: function(){
            var currentPage = document.location.href;
            var pattern = /\//gs;
            var slashes = currentPage.match(pattern);
            var root = "";
            for(var i=0, iLen = slashes.length - 3; i<= iLen; i++){
                root += "../";
            }
            //console.log("root", root);
            return root;

    },
    getParam: function($route, what){
        return $route.params[what] ? $route.params[what] : $route.query[what];
    },
    decodeHtml: function(html) {
        var txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    }
}

common.init();

export default common;